
import {defineComponent} from "vue";

export default defineComponent({
  name: "BaseButton",
  props: {
    clazz: {type: String},
    type: {type: String, default: () => 'button'},
    submitting: {type: Boolean, default: () => false},
  },
  emits: ['click'],
  setup(_, {emit}) {
    const click = () => {
      emit('click')
    }
    return {
      click
    }
  }
})
