
import {defineComponent, ref} from "vue";
import {Form} from 'vee-validate';
import store from "@/store";
import BaseButton from "@/components/base/form/BaseButton.vue";
import Swal from "sweetalert2";

export default defineComponent({
  name: "BaseForm",
  components: {BaseButton, Form},
  props: {
    validate: {},
    submitting: {required: false, default: false, type: Boolean},
    discard: {required: false, default: false},
    cancel: {require: false, default: false},
  },
  emits: ['form-discard', 'form-submit', 'form-cancel'],
  setup(_, {emit}) {
    const baseForm = ref<HTMLFormElement | null>(null);
    const formDiscard = () => {
      emit('form-discard')
    }
    const formSubmit = () => {
      emit('form-submit')
    }
    const formCancel = () => {
      emit('form-cancel')
    }
    store.subscribe((mutation, state) => {
      if (mutation.type === 'setFormError') {
        baseForm.value?.setErrors(state.ValidationModule.errors)
      }
    });
    return {
      formSubmit,
      formDiscard,
      baseForm,
      formCancel,
    }
  },
  methods: {

    onClickDiscard() {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, reset it',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: () => {
          this.formDiscard()
        }
      })
    }
  }
})
