
import {Field} from 'vee-validate'
import {defineComponent, ref, watch} from "vue";

export default defineComponent({
  name: "BaseSelect",
  components: {
    Field
  },
  props: {
    name: {type: String, required: true},
    modelValue: {},
    noSelect: {required: false, default: true},
    data: {type: Array, required: true}
  },
  emits: ['update:modelValue', 'onSelected'],
  setup(props) {
    const model = ref(props.modelValue);
    watch(() => props.modelValue, (cb) => {
      model.value = cb
    })
    const onReset = () => {
      model.value = null;
    }
    return {
      model,
      onReset,
    }
  },
  methods: {
    onSelect(event) {
      this.$emit('update:modelValue', event.target.value)
      this.$emit('onSelected', event.target.value)
    }
  }
})
