
import { defineComponent, nextTick, onMounted } from "vue";
import { reinitializeComponents } from "@/core/plugins/keenthemes";

export default defineComponent({
  name: "QuickAction",
  data() {
    return {
      show: false,
    }
  },
  props: {
    clazz: {type:String, default:'quick-action'},
    size: {required:false, default: 'w-125px'}
  },
  setup() {
    onMounted(() => {
      nextTick(() => {
        reinitializeComponents();
      });
    })
  },
  methods: {
    onClose() {
      this.show = false;
    }
  },
})
