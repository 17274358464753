
import {computed, defineComponent, ref} from "vue";
import {useUserGroupStore} from "@/store/usergroup.store";
import {PinaFilterObjects} from "@/core/composite/composite";
import QuickAction from "@/components/base/action/QuickAction.vue";
import Entities from "@/components/base/common/Entities.vue";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import UserStatus from "@/views/user/UserStatus.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import UserService from "@/core/services/UserService";
import InviteUserForm from "@/views/user/InviteUserForm.vue";
import {useToast} from "vue-toast-notification";
import InfoBox from "@/components/base/InfoBox.vue";
import HelpEmbed from "@/components/base/HelpEmbed.vue";
import UserGroupService from "@/core/services/UserGroupService";
import OptOut from "@/components/base/OptOut.vue";

export default defineComponent({
  name: "CompanyUser",
  components: {
    OptOut,
    HelpEmbed,
    InfoBox, InviteUserForm, BaseModal, UserStatus, DateTimeFormat, KtDatatable, Entities, QuickAction
  },
  props: {
    companyId: {}
  },
  setup(props) {
    const filterObjects = ref({groupId: props.companyId});
    const useGroupStore = useUserGroupStore();
    const page = computed(() => useGroupStore.page);
    const tableHeader = ref([
      {
        name: "Name",
        key: "fullName",
        sortable: true,
      },
      {
        name: "Last Login",
        key: "lastLogin",
        sortable: true,
      },
      {
        name: "Status",
        key: "status",
        sortable: true,
      },
      {
        name: "",
        key: "actions",
        sortable: true,
      },
    ]);
    return {
      tableHeader,
      page,
      ...PinaFilterObjects(useGroupStore, filterObjects.value, ['user'])
    }
  },
  methods: {
    invite() {
      const modal = this.$refs.inviteNewUserRef as typeof BaseModal
      modal.showBaseModal();
    },
    onInvited() {
      this.paginationDataLoad();
      const modal = this.$refs.inviteNewUserRef as typeof BaseModal;
      modal.hideBaseModal();
    },
    onUpdateStatus(id, status) {
      UserGroupService.updateStatus(id, status).then(() => {
        this.paginationDataLoad()
      })
    },
    onResendInvite(id) {
      UserService.reInvite(id).then(() => {
        useToast().success('Sent successfully', {position: 'top-right'})
      })
    }
  }
})
