import {BaseService} from "@/core/services/BaseService";
import {generatorQueryString} from "@/core/helpers/functions";

class UserGroupService extends BaseService<any> {
  endpoint = "/user-group"

  async findUsers(filter): Promise<any> {
    const queryString = generatorQueryString(filter);
    return this.http().get<any>(this.endpoint + "/users" + "?" + queryString).then(res => {
      return res.data
    })
  }

  async findGroups(filter): Promise<any> {
    const queryString = generatorQueryString(filter);
    return this.http().get<any>(this.endpoint + "/groups" + "?" + queryString).then(res => {
      return res.data
    })
  }

  async updateStatus(id, status) {
    return this.http().put<any>(this.endpoint + "/" + id + "/status/" + status).then(res => {
      return res.data
    })
  }
}

export default new UserGroupService();

