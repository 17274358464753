import {defineStore} from "pinia";
import UserGroupService from "@/core/services/UserGroupService";
import {useLoadingStore} from "@/store/loading";

export const useUserGroupStore = defineStore('userGroupStore', {
  state: () => ({
    page: <any>{
      total: 0,
      data: [],
      totalPages: 0,
    },
    pageSecond: <any>{
      total: 0,
      data: [],
      totalPages: 0,
    }
  }),
  actions: {
    list(filter) {
      const loadingStore = useLoadingStore();
      loadingStore.setEntitiesLoading(true);
      UserGroupService.findUsers(filter).then(res => {
        this.page = res;
      }).finally(() => {
        loadingStore.setEntitiesLoading(false)
      })
    },

    listSecond(filter) {
      const loadingStore = useLoadingStore();
      loadingStore.setEntitiesLoading(true);
      UserGroupService.findGroups(filter).then(res => {
        this.pageSecond = res;
      }).finally(() => {
        loadingStore.setEntitiesLoading(false)
      })
    }
  }
})