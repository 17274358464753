import {Modal} from "bootstrap";

const hideModal = (modalEl: HTMLElement | null): void => {
  if (!modalEl) {
    return;
  }

  const myModal = Modal.getInstance(modalEl);
  myModal.hide();
};

const showModal = (modalEl: HTMLElement | null): void => {
  if (!modalEl) {
    return;
  }
  const myModal = new Modal(modalEl)
  myModal.show();
}

const removeLinkActive = () => {
  const link = document?.getElementById("main-overview")
  if (link) {
    link.classList.remove("active")
  }
}
const removeModalBackdrop = (): void => {
  if (document.querySelectorAll(".modal-backdrop.fade.show").length) {
    document.querySelectorAll(".modal-backdrop.fade.show").forEach((item) => {
      item.remove();
    });
  }
};

export {removeModalBackdrop, hideModal, showModal, removeLinkActive};
