
import {defineComponent, ref, watch} from "vue";

export default defineComponent({
  name: "UserStatus",
  props: {
    status: {}
  },
  setup(props) {
    const objectStatus = ref({clazz: '', title: ''})
    const buildClass = (status) => {
      switch (status) {
        case 'INVITED':
          objectStatus.value = {clazz: 'badge-light-primary', title: 'Invited'}
          break;
        case 'ACTIVE':
        case 'ACTIVATED':
          objectStatus.value = {clazz: 'badge-light-success', title: 'Active'}
          break;

        case 'DEACTIVATED':
          objectStatus.value = {clazz: 'badge-light-danger', title: 'Inactive'}
          break;
      }
    }
    buildClass(props.status)
    watch(() => props.status, cb => {
      buildClass(cb)
    })
    return {
      objectStatus
    }
  }
})
