
import {computed, defineComponent, ref} from "vue";
import EntityMetaService from "@/core/services/EntityMetaService";
import store from "@/store";

export default defineComponent({
  name: "OptOut",
  props: {
    boxId: {required: true},
  },
  setup(props) {
    const user = computed(() => store.getters.currentUser);
    const show = ref<boolean>(false);
    const checkShow = (boxId) => {
      EntityMetaService.getMeta(boxId, 'OPT_OUT', 'OPT_OUT').then(res => {
        show.value = !res.id;
      })
    }
    checkShow(props.boxId)
    return {
      user,
      show,
    }
  },
  methods: {
    onDismiss() {
      const model = {
        objId1: this.user.id,
        objType1: 'USER',
        objId2: this.boxId,
        objType2: 'OPT_OUT',
        type: 'OPT_OUT'
      }
      EntityMetaService.create(model).then(() => {
        this.show = false
      })
    }
  }
})
